@media (orientation: portrait) {
    .containerError {
        display: flex;
        position: fixed;
        bottom: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 30vw;
        background-color: #FF0000;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 1.5rem;
        z-index: 2;
        font-size: 1.5rem;
        border-radius: 10px;
        animation-name: snackBarAnimationError;
        animation-duration: 1.2s;
        animation-timing-function: ease;
    }

    @keyframes snackBarAnimationError {
        0% {
            bottom: 0;
            opacity: 0;
        }

        100% {
            bottom: 100;
            opacity: 1;
        }
    }

    .firstError {
        width: 10%;
        font-size: 2.0rem;
        margin: auto auto;
    }

    .secondError {
        width: 90%;
        margin: auto auto;
    }

    @media(max-width: 1280px) {
        .containerError {
            min-width: 80vw;
            padding: 1.2rem;
        }

        .firstError {
            font-size: 1.9rem;
        }

        .secondError {
            font-size: 1.7rem;
        }
    }

    @media(max-width: 540px) {
        .containerError {
            min-width: 80vw;
            padding: 0.9rem;
        }

        .firstError {
            font-size: 1.2rem;
        }

        .secondError {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 376px) {
        .containerError {
            min-width: 80vw;
            padding: 0.9rem;
        }

        .firstError {
            font-size: 1.1rem;
        }

        .secondError {
            font-size: 0.8rem;
        }
    }

    @media(max-width: 281px) {
        .containerError {
            min-width: 80vw;
            padding: 0.8rem;
        }

        .firstError {
            font-size: 1.0rem;
        }

        .secondError {
            font-size: 0.6rem;
        }
    }

}

@media (orientation: landscape) {
    .containerError {
        display: flex;
        position: fixed;
        bottom: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 30vw;
        background-color: #FF0000;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 1.5rem;
        z-index: 2;
        font-size: 1.5rem;
        border-radius: 10px;
        animation-name: snackBarAnimationError;
        animation-duration: 1.2s;
        animation-timing-function: ease;
    }

    @keyframes snackBarAnimationError {
        0% {
            bottom: 0;
            opacity: 0;
        }

        100% {
            bottom: 100;
            opacity: 1;
        }
    }

    .firstError {
        width: 10%;
        font-size: 2.0rem;
        margin: auto auto;
    }

    .secondError {
        width: 90%;
        margin: auto auto;
    }

    @media(max-width: 1680px) {
        .containerError {
            min-width: 60vw;
            padding: 1.8rem;
        }

        .firstError {
            font-size: 2.2rem;
        }

        .secondError {
            font-size: 2.0rem;
        }
    }

    @media(max-width: 1280px) {
        .containerError {
            min-width: 60vw;
            padding: 1.2rem;
        }

        .firstError {
            font-size: 1.8rem;
        }

        .secondError {
            font-size: 1.6rem;
        }
    }

    @media(max-width: 916px) {
        .containerError {
            min-width: 70vw;
            padding: 0.8rem;
        }

        .firstError {
            font-size: 1.6rem;
        }

        .secondError {
            font-size: 1.4rem;
        }
    }


    @media(max-width: 721px) {
        .containerError {
            min-width: 60vw;
            padding: 0.8rem;
        }

        .firstError {
            font-size: 1.6rem;
        }

        .secondError {
            font-size: 1.0rem;
        }
    }
}