@media (orientation: portrait) {
    .positionTeleport {
        display: flex;
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .constantTeleport {
        background-color: rgb(136, 22, 243);
        font-size: 1.6rem;
        border-radius: 5px;
        font-family: Arial, Helvetica, sans-serif;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 13rem;
        height: 3rem;
        cursor: pointer;
        text-decoration: none;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 1280px) {
        .positionTeleport {
            bottom: 0%;
        }
    }

    @media(max-width: 540px) {
        .positionTeleport {
            bottom: 0%;
        }
    }

    @media(max-width: 281px) {
        .constantTeleport {
            width: 10rem;
            height: 3rem;
            font-size: 1.2rem;
        }
    }
}

@media (orientation: landscape) {
    .positionTeleport {
        display: flex;
        position: absolute;
        bottom: -10%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .constantTeleport {
        background-color: rgb(136, 22, 243);
        font-size: 1.6rem;
        border-radius: 5px;
        font-family: Arial, Helvetica, sans-serif;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 13rem;
        height: 3rem;
        cursor: pointer;
        text-decoration: none;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 1280px) {
        .positionTeleport {
            bottom: -5%;
            height: 2rem;
        }
    }

    @media(max-width: 1025px) {
        .positionTeleport {
            bottom: -10%;
            height: 2rem;
        }
    }

    @media(max-width: 916px) {
        .positionTeleport {
            bottom: -10%;
            height: 2rem;
        }

        .constantTeleport {
            width: 9rem;
            height: 2.8rem;
            font-size: 1.0rem;
        }
    }

    @media(max-width: 721px) {
        .positionTeleport {
            bottom: -25%;
            height: 2rem;
        }

        .constantTeleport {
            width: 10rem;
            height: 3rem;
            font-size: 1.2rem;
        }
    }

    @media(max-width: 668px) {
        .positionTeleport {
            bottom: -15%;
            height: 2rem;
        }

        .constantTeleport {
            width: 8rem;
            height: 2rem;
            font-size: 0.8rem;
        }
    }

    @media(max-width: 654px) {
        .positionTeleport {
            bottom: -28%;
            height: 2rem;
        }

        .constantTeleport {
            width: 8rem;
            height: 2rem;
            font-size: 0.8rem;
        }
    }
}