
.textShadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.successBar {
  display: block;
}

@media (orientation: portrait) {
  html {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .teleport {
    position: absolute;
    top: 2%;
    right: 1%;
    cursor: pointer;
    text-decoration: none;
    color: white;
    z-index: 2;
    font-size: 3rem;
  }

  .App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
  }

  .App-header {
    background-image: url("header-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60vh;
    min-width: 100vw;
  }

  .Logo {
    width: 360px;
    height: 100px;
    animation-name: logoAnimation;
    animation-duration: 1.2s;
    animation-timing-function: ease;
  }

  @keyframes logoAnimation {
    0% {
      width: 0;
      height: 0;
    }

    100% {
      width: 100;
      height: 100;
    }
  }

  .Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  .First {
    color: white;
    text-shadow: 0 0 10px #000000;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 100;
    line-height: 2rem;
    letter-spacing: 0.1em;
    width: 100vw;
    margin: 3rem 0;
  }

  .Second {
    color: white;
    font-size: 2rem;
    font-weight: 110;
    line-height: 2rem;
    letter-spacing: 0.1em;
    width: 70vw;
    font-weight: bold;
    margin: 4rem auto;
  }

  .Third {
    color: white;
    font-size: 2rem;
    font-weight: 110;
    line-height: 2rem;
    letter-spacing: 0.1em;
    width: 75vw;
    font-weight: bold;
    margin: 2.5rem auto 1.5rem auto;
  }

  .Fourth {
    width: 100vw;
    margin: 3rem 0;
  }

  .horizontal_login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
    align-items: center;
  }

  .successBar {
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
    align-items: center;
  }

  .button {
    background-color: rgb(136, 22, 243);
    font-size: 2rem;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 13rem;
    height: 3rem;
    cursor: pointer;
  }

  .name {
    width: 28rem;
    height: 3rem;
    border-radius: 5px;
    font-size: 1.5rem;
    text-align: center;
  }

  .telephone {
    width: 28rem;
    height: 3rem;
    border-radius: 5px;
    font-size: 1.5rem;
    text-align: center;
  }

  @media(max-width: 1280px) {
    .Container {
      top: 54%;
    }

    .First {
      font-size: 1.3rem;
      margin: 1rem 0 1rem 0;
    }

    .Second {
      font-size: 1.5rem;
      margin: 1.4rem auto;
    }

    .Third {
      font-size: 1.5rem;
      margin: 1.4rem auto;
    }

    .name {
      width: 20rem;
      height: 2rem;
      font-size: 0.9rem;
    }

    .telephone {
      width: 20rem;
      height: 2rem;
      font-size: 0.9rem;
    }
  }


  @media(max-width: 913px) {
    .Logo {
      width: 436px;
      height: 120px;
    }

    .Container {
      top: 50%;
    }

    .First {
      font-size: 2.4rem;
      margin: 3rem 0 3rem 0;
    }

    .Second {
      font-size: 2.2rem;
      margin: 1.0rem auto;
    }

    .Third {
      font-size: 2.2rem;
      margin: 1.0rem auto;
    }

    .name {
      width: 36rem;
      height: 4rem;
      font-size: 1.9rem;
    }

    .telephone {
      width: 36rem;
      height: 4rem;
      font-size: 1.9rem;
    }

    .button {
      font-size: 2.5rem;
      width: 15rem;
      height: 4rem;
    }

  }

  @media(max-width: 824px) {
    .Container {
      top: 50%;
    }

    .First {
      font-size: 1.9rem;
      margin: 3rem 0 1rem 0;
    }

    .Second {
      font-size: 1.3rem;
      font-weight: 110;
      line-height: 1.5rem;
      width: 80vw;
      font-weight: bold;
      margin: 2rem auto;
    }

    .Third {
      font-size: 1.3rem;
      width: 75vw;
      font-weight: bold;
      margin: 0.6rem auto 0.6rem auto;
      line-height: 1.5rem;
    }

    .Fourth {
      margin: 1rem 0;
    }

    .name {
      width: 26rem;
      height: 3rem;
      font-size: 1.7rem;
    }

    .telephone {
      width: 26rem;
      height: 3rem;
      font-size: 1.7rem;
    }

    .Logo {
      width: 327px;
      height: 90px;
    }

    .button {
      font-size: 1.4rem;
      width: 8rem;
      height: 2.5rem;
    }

    .horizontal_login {
      height: 18vh;
    }
  }

  @media (max-width: 540px) {
    .Container {
      top: 50%;
    }

    .First {
      font-size: 1.3rem;
      margin: 1rem 0 1rem 0;
    }

    .Second {
      font-size: 1.0rem;
      font-weight: 110;
      line-height: 1.5rem;
      width: 80vw;
      font-weight: bold;
      margin: 2rem auto;
    }

    .Third {
      font-size: 1rem;
      width: 75vw;
      font-weight: bold;
      margin: 0.6rem auto 0.6rem auto;
      line-height: 1.5rem;
    }

    .Fourth {
      margin: 1rem 0;
    }

    .name {
      width: 20rem;
      height: 2rem;
      font-size: 1.0rem;
    }

    .telephone {
      width: 20rem;
      height: 2rem;
      font-size: 1.0rem;
    }

    .Logo {
      width: 218px;
      height: 60px;
    }

    .button {
      font-size: 1.4rem;
      width: 8rem;
      height: 2.5rem;
    }

    .horizontal_login {
      height: 18vh;
    }
  }

  @media (max-width: 280px) {
    .Logo {
      width: 218px;
      height: 60px;
    }

    .Container {
      top: 50%;
    }

    .First {
      font-size: 0.9rem;
      margin: 0.4rem 0 0.4rem 0;
    }

    .Second {
      font-size: 0.9rem;
      margin: 1rem auto;
    }

    .button {
      font-size: 1.4rem;
      width: 8rem;
      height: 2.5rem;
    }

    .name {
      width: 16rem;
    }

    .telephone {
      width: 16rem;
    }

    .teleport {
      top: 1%;
      right: 1%;
      font-size: 2rem;
    }

  }

}

@media (orientation: landscape) {
  html {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  .teleport {
    position: absolute;
    top: 2%;
    right: 1%;
    cursor: pointer;
    text-decoration: none;
    color: white;
    z-index: 2;
    font-size: 3rem;
  }

  .App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
  }

  .App-header {
    background-image: url("header-bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60vh;
    min-width: 100vw;
  }

  .Logo {
    width: 360px;
    height: 100px;
    animation-name: logoAnimation;
    animation-duration: 1.2s;
    animation-timing-function: ease;
  }

  @keyframes logoAnimation {
    0% {
      width: 0;
      height: 0;
    }

    100% {
      width: 100;
      height: 100;
    }
  }

  .Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  .First {
    color: white;
    text-shadow: 0 0 10px #000000;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 100;
    line-height: 2rem;
    letter-spacing: 0.1em;
    width: 100vw;
    margin: 3rem 0;
  }

  .Second {
    color: white;
    font-size: 2rem;
    font-weight: 110;
    line-height: 2rem;
    letter-spacing: 0.1em;
    width: 70vw;
    font-weight: bold;
    margin: 4rem auto;
  }

  .Third {
    color: white;
    font-size: 2rem;
    font-weight: 110;
    line-height: 2rem;
    letter-spacing: 0.1em;
    width: 75vw;
    font-weight: bold;
    margin: 2.5rem auto 1.5rem auto;
  }

  .Fourth {
    width: 100vw;
    margin: 3rem 0;
  }

  .horizontal_login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
    align-items: center;
  }

  .successBar {
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
    align-items: center;
  }


  .button {
    background-color: rgb(136, 22, 243);
    font-size: 2rem;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 13rem;
    height: 3rem;
    cursor: pointer;
  }

  .name {
    width: 28rem;
    height: 3rem;
    border-radius: 5px;
    font-size: 1.5rem;
    text-align: center;
  }

  .telephone {
    width: 28rem;
    height: 3rem;
    border-radius: 5px;
    font-size: 1.5rem;
    text-align: center;
  }

  @media(max-width: 3110px) {
    .Container {
      top: 45%;
    }

    .Logo {
      width: 432px;
      height: 120px;
    }

    .First {
      font-size: 2.0rem;
      margin: 1.6rem 0;
    }

    .Second {
      font-size: 2.0rem;
      margin: 1.3rem auto;
    }

    .Third {
      font-size: 2.0rem;
      margin: 1.3rem auto;
    }

    .Fourth {
      width: 100vw;
      margin: 1.0rem 0;
    }

    .name {
      width: 30rem;
      height: 2.4rem;
      font-size: 1.6rem;
    }

    .telephone {
      width: 30rem;
      height: 2.4rem;
      font-size: 1.6rem;
    }

    .button {
      font-size: 2rem;
      width: 13rem;
      height: 3rem;
    }

    .horizontal_login {
      height: 9.5rem;
    }

    .successBar {
      height: 9.5rem;
    }
  }

  @media(max-width: 2000px) {
    .Container {
      top: 50%;
    }

    .Logo {
      width: 432px;
      height: 120px;
    }

    .First {
      font-size: 2.0rem;
      margin: 1.6rem 0;
    }

    .Second {
      font-size: 2.0rem;
      margin: 1.3rem auto;
    }

    .Third {
      font-size: 2.0rem;
      margin: 1.3rem auto;
    }

    .Fourth {
      width: 100vw;
      margin: 1.0rem 0;
    }

    .name {
      width: 30rem;
      height: 2.4rem;
      font-size: 1.6rem;
    }

    .telephone {
      width: 30rem;
      height: 2.4rem;
      font-size: 1.6rem;
    }

    .button {
      font-size: 2rem;
      width: 13rem;
      height: 3rem;
    }

    .horizontal_login {
      height: 9.5rem;
    }

    .successBar {
      height: 9.5rem;
    }
  }

  @media(max-width: 1281px) {
    .Container {
      top: 50%;
    }

    .Logo {
      width: 389px;
      height: 108px;
    }

    .First {
      font-size: 2.0rem;
      margin: 1.6rem 0;
    }

    .Second {
      font-size: 2.0rem;
      margin: 1.3rem auto;
    }

    .Third {
      font-size: 2.0rem;
      margin: 1.3rem auto;
    }

    .Fourth {
      width: 100vw;
      margin: 1.0rem 0;
    }

    .name {
      width: 30rem;
      height: 2.4rem;
      font-size: 1.6rem;
    }

    .telephone {
      width: 30rem;
      height: 2.4rem;
      font-size: 1.6rem;
    }

    .button {
      font-size: 2rem;
      width: 13rem;
      height: 3rem;
    }

    .horizontal_login {
      height: 9.5rem;
    }

    .successBar {
      height: 9.5rem;
    }
  }

  @media(max-width: 1025px) {
    .Container {
      top: 50%;
    }

    .Logo {
      width: 346px;
      height: 96px;
    }

    .First {
      font-size: 1.6rem;
      margin: 1.0rem 0;
    }

    .Second {
      font-size: 1.5rem;
      margin: 1.0rem auto;
    }

    .Third {
      font-size: 1.5rem;
      margin: 1.3rem auto;
    }

    .Fourth {
      width: 100vw;
      margin: 1.0rem 0;
    }

    .name {
      width: 26rem;
      height: 2.0rem;
      font-size: 1.6rem;
    }

    .telephone {
      width: 26rem;
      height: 2.0rem;
      font-size: 1.6rem;
    }

    .button {
      font-size: 2rem;
      width: 13rem;
      height: 3rem;
    }

    .horizontal_login {
      height: 8.5rem;
    }

    .successBar {
      height: 8.5rem;
    }
  }

  @media(max-width: 916px),
  (max-height: 635px) {
    .Container {
      top: 45%;
    }

    .Logo {
      width: 216px;
      height: 60px;
    }

    .First {
      font-size: 1.0rem;
      margin: 0.6rem 0;
    }

    .Second {
      font-size: 1.0rem;
      margin: 0.3rem auto;
    }

    .Third {
      font-size: 1.0rem;
      margin: 0.3rem auto;
    }

    .Fourth {
      width: 100vw;
      margin: 1.0rem 0;
    }

    .name {
      width: 20rem;
      min-height: 1.6rem;
      font-size: 1.3rem;
    }

    .telephone {
      width: 20rem;
      min-height: 1.6rem;
      font-size: 1.3rem;
    }

    .button {
      font-size: 1.2rem;
      width: 7rem;
      height: 2.6rem;
    }

    .horizontal_login {
      height: 1.9rem;
    }

    .successBar {
      height: 1.9rem;
    }
  }

  @media (max-width: 740px) {
    .Container {
      top: 45%;
    }

    .First {
      font-size: 1.0rem;
      margin: 0.3rem 0;
    }

    .Second {
      font-size: 1.0rem;
      width: 80vw;
      margin: 0.5rem auto;
    }

    .Third {
      font-size: 1rem;
      width: 95vw;
      font-weight: bold;
      margin: 0.6rem auto 0.6rem auto;
      line-height: 1.5rem;
    }

    .Fourth {
      margin: 0.5rem 0;
    }

    .name {
      width: 15rem;
      font-size: 0.88rem;
      min-height: 1rem;
    }

    .telephone {
      width: 15rem;
      min-height: 1rem;
      font-size: 0.88rem;
    }

    .button {
      font-size: 1rem;
      width: 6rem;
      height: 2rem;
    }

    .horizontal_login {
      height: 1.8rem;
    }

    .successBar {
      height: 1.8rem;
    }


  }

  @media (max-width: 653px) {
    .Logo {
      width: 130px;
      height: 36px;
    }

    .Container {
      top: 40%;
    }

    .name {
      width: 15rem;
      height: 1.8rem;
    }

    .telephone {
      width: 15rem;
      height: 1.8rem;
    }

    .First {
      font-size: 0.9rem;
      margin: 0.2rem 0;
    }

    .Second {
      font-size: 0.9rem;
      margin: 0.3rem auto;
      line-height: 1rem;
    }

    .Third {
      font-size: 0.9rem;
      width: 95vw;
      font-weight: bold;
      margin: 0.3rem auto;
      line-height: 1.0rem;
    }

    .button {
      font-size: 1rem;
      width: 6rem;
      height: 2rem;
    }

    .horizontal_login {
      height: 1.0rem;
    }

    .teleport {
      top: 1%;
      right: 1%;
      font-size: 2rem;
    }

  }

}