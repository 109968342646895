
.containerSuccess {
    background-color: #1d8f0e;
}

@media (orientation: portrait) {
    .containerSuccess {
        display: flex;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 30vw;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 1.5rem;
        z-index: 2;
        font-size: 1.5rem;
        border-radius: 10px;
    }

    .firstSuccess {
        width: 10%;
        font-size: 4.0rem;
        margin: auto auto;
    }

    .secondSuccess {
        width: 90%;
        margin: auto auto;
    }

    @media(max-width: 1280px) {
        .containerSuccess {
            min-width: 60vw;
            border-radius: 10px;
            font-size: 1.5rem;
            bottom: 6%;
        }
    }

    @media(max-width: 540px),
    (max-height: 640px) {
        .containerSuccess {
            min-width: 70vw;
            bottom: 12%;
            padding: 0.8rem;
            font-size: 1.2rem;
        }

        .firstSuccess {
            width: 10%;
            font-size: 2.0rem;
            margin: auto auto;
        }

        .secondSuccess {
            width: 90%;
            margin: auto auto;
        }
    }

    @media(max-width: 281px) {
        .containerSuccess {
            min-width: 60vw;
            padding: 0.8rem;
            bottom: 14%;
        }

        .firstSuccess {
            font-size: 1.4rem;
        }

        .secondSuccess {
            font-size: 0.8rem;
        }
    }
}

@media (orientation: landscape) {
    .containerSuccess {
        display: flex;
        position: absolute;
        bottom: -5%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 30vw;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 1.5rem;
        z-index: 2;
        font-size: 1.5rem;
        border-radius: 10px;
    }

    .firstSuccess {
        width: 10%;
        font-size: 4.0rem;
        margin: auto auto;
    }

    .secondSuccess {
        width: 90%;
        margin: auto auto;
    }

    @media(max-width: 1280px) {
        .containerSuccess {
            min-width: 60vw;
            bottom: 5%;
            padding: 1.0rem;
        }

        .firstSuccess {
            font-size: 2.0rem;
        }

        .secondSuccess {
            font-size: 1.8rem;
        }
    }

    @media(max-width: 1025px) {
        .containerSuccess {
            min-width: 60vw;
            bottom: 0%;
            padding: 1.0rem;
        }
    }

    @media(max-width: 916px) {
        .containerSuccess {
            min-width: 40vw;
            bottom: 0%;
            padding: 0.6rem;
        }

        .firstSuccess {
            font-size: 1.2rem;
        }

        .secondSuccess {
            font-size: 1.0rem;
        }
    }

    @media(max-width: 721px) {
        .containerSuccess {
            min-width: 60vw;
            bottom: -10%;
            padding: 0.6rem;
        }

        .firstSuccess {
            font-size: 1.2rem;
        }

        .secondSuccess {
            font-size: 1.0rem;
        }
    }

    @media(max-width: 668px) {
        .containerSuccess {
            min-width: 60vw;
            bottom: -5%;
            padding: 0.6rem;
        }

        .firstSuccess {
            font-size: 1.2rem;
        }

        .secondSuccess {
            font-size: 1.0rem;
        }
    }

    @media(max-width: 654px) {
        .containerSuccess {
            min-width: 60vw;
            bottom: -12%;
            padding: 0.6rem;
        }

        .firstSuccess {
            font-size: 1.2rem;
        }

        .secondSuccess {
            font-size: 1.0rem;
        }
    }

}